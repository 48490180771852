<template>
  <Template v-bind="params" :options="getOptions()" @goNext="(items) => onNext(items)" :is-loading="isLoading" />
</template>

<script>
import Template from "../components/templates/InterestAndActivity/InterestAndActivity.vue";
import { c } from "../components/constants";
export default {
  components: {
    Template,
  },
  data() {
    console.log(this.state.currentUser)
    return {
      params: c.interests,
      isLoading: false,
    };
  },
  methods: {
    getOptions() {
      const options = [];
      [...this.state.tag.tags?.filter((t) => t.category === "DESCRIPTORS")].map((t) => options.push(t.name));
      return options;
    },
    async onNext(items) {
      if (this.isLoading) return false;
      try {
        this.isLoading = true;

        console.log("items: ", items);

        const tags = [];
        const newTags = [];
        items.map((item) => {
          let tag = this.state.tag.tags.find((t) => t.name === item);
          tag
            ? tags.push({ id: tag.id })
            : newTags.push({
              name: item.toLowerCase(),
              nameLower: item.toLowerCase(),
              status: "NEW",
              type: "DEMOGRAPHIC",
              category: 'DESCRIPTORS',
              targetType: "INDIVIDUALS",
            });
        });

        console.log("tags: ", tags);
        console.log("newTags: ", newTags);
        console.log("here", this.state.currentUser?.id);

        await this.actions.user.saveUser({
          where: { id: this.state.currentUser?.id },
          data: {
            tags: {
              connect: tags,
              create: newTags,
            },
          },
        });
        console.log(this.state.currentUser, "user");
        this.actions.alert.showSuccess({
          message: "Saved your interests and activities successfully!",
        });
        this.$router.push({ name: "Dashboard", query: { registered: true } });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    await this.actions.tag.getTags({ all: true, where: { status: 'APPROVED' } });
  },
};
</script>
